const app = (state = {}, action) => {
    switch (action.type) {
        case 'SET_APP_DATA':
            return { ...state,
                     ...action.payload }
        default:
            return state
    }
}

export default app
