import { postFetchMethod, validateRequest } from '../util/fetch-functions'

export const logCase = function( type = "info", message, data ) {

    return (dispatch, getState) => {
        let state = getState();

        let log = {
            type: type,
            message: message,
            body: { data:data,
                    monitor: state.app },
        }

        fetch( '/log', { ...postFetchMethod, body: JSON.stringify(log) } )
            .then( validateRequest )
            .then( response => response.json() )
            .then( data => {
                // console.log( data );
            }).catch(function(error) {
                console.log( error );
            });

        dispatch( setLog( log ) );
    }

}


export const setLog = function( data ) {

    return {
        type: 'SET_APP_LOG',
        payload: data
    }
}
