import React, { Component } from 'react';
import { connect } from 'react-redux';

/* Component */
import { PlaylistImage, PlaylistVideo } from "./PlaylistItem"


class PlaylistSlide extends Component {


    constructor(props) {
        super(props);

        this.nextSlide = this.nextSlide.bind(this);

    }

    nextSlide() {

        this.props.slider.current.slickNext();
    }

    render() {

        let { slider, slide, active, orientation,resourceHost } = this.props;

        let SlideProps = {
            ...slide,
            resourceHost: resourceHost,
            active: active,
            orientation: orientation,
            onDone: this.nextSlide,
        }

        console.log('SlideProps');
        console.log(SlideProps);

        switch(slide.type) {
          case "paragraph--playlist_image_media":
            // code block
            return <PlaylistImage {...SlideProps} />
            break;
          case "paragraph--playlist_video_media":
            // code block
            return <PlaylistVideo {...SlideProps} />
            break;
          default:
            // code block
            return (
                <div className="playlist__slide">
                    slide
                    <button onClick={this.nextSlide} > hit </button>
                </div>
            )
        }

    }
}


export default PlaylistSlide
