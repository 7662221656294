import React, { Component } from 'react';
import { connect } from 'react-redux';

/* Components */
import PlaylistSlider from "./PlaylistSlider"

/** Actions */
import { logCase } from '../actions/logs'

class PlaylistContainer extends Component {


    constructor(props) {
        super(props);

        this.LogPlaylistState = this.LogPlaylistState.bind(this);

        this.prepareActivePlaylist = this.prepareActivePlaylist.bind(this);
    }


    componentDidMount() {
        this.LogPlaylistState("Playlist got mounted");
    }

    componentDidUpdate(prevProps) {
        if (this.props.currentMenu !== prevProps.currentMenu) {
            this.LogPlaylistState("Playlist updated");
        }
    }

    componentWillUnmount() {
        this.LogPlaylistState("Playlist getting unmounted");
    }


    LogPlaylistState(message, data) {
        let cleanData = Object.keys(this.props.playlists);

        this.props.logCase("info", message, cleanData);
    }

    prepareActivePlaylist() {

        let activeID = Object.keys(this.props.playlists).slice(0, 1);

        return this.props.playlists[activeID];

    }

    render() {

        let activePlaylist = this.prepareActivePlaylist();

        return (
            <div className="playlist">
                { activePlaylist  ? (
                        <PlaylistSlider
                            slides={activePlaylist.field_playlist.relationships.field_ds_playlist_media.data} />
                    ) : null }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        playlists: state.playlists,
        // windowVisible: state.app.windowVisible,
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        logCase: (type, message, cleanData) => {
            dispatch(logCase(type, message, cleanData));
        },
        // visibilityUpdate: (visibilityState) => {
        //     dispatch(pageVisible(visibilityState))
        // }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(PlaylistContainer)
