import React, { Component } from 'react';
import { Player, ControlBar } from 'video-react';



export class PlaylistImage extends React.Component {

    render() {

        const handleActive = () => setTimeout(this.props.onDone, 30000)
        const imageDetails = this.props.orientation == "portrait" && !!this.props.relationships.field_ds_pl_image_p.data ? this.props.relationships.field_ds_pl_image_p.data : this.props.relationships.field_ds_pl_image.data;
        const resourceHost = this.props.resourceHost;

        return (
            <PlaylistItem {...this.props} handleActive={handleActive} >
                { imageDetails ? (
                        <img src={resourceHost + imageDetails.attributes.uri.url } {...imageDetails.meta} />
                   ) : null }
            </PlaylistItem>
        )
    }
}

//https://video-react.js.org/components/player/#getState

export class PlaylistVideo extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
           player: {},
        };


        this.handleActive = this.handleActive.bind(this);
        this.handleInactive = this.handleInactive.bind(this);
    }

    componentDidMount() {
        // subscribe state change
        this.player.subscribeToStateChange(this.handleStateChange.bind(this));
    }
    handleStateChange(state) {
        if (state.currentTime == state.duration) {
            this.props.onDone();
        }
    }
    handleActive() {

        this.player.load();
        // this.player.play();
    }

    handleInactive() {

        this.player.pause();
    }

    render() {
        console.log('this.props');
        console.log(this.props);

        const videoSrc = this.props.orientation == "portrait" && !!this.props.field_ds_pl_video_p  ? this.props.attributes.field_ds_pl_video_p : this.props.attributes.field_ds_pl_video;

        return (
            <PlaylistItem { ...this.props } handleActive={ this.handleActive } handleInactive={this.handleInactive}>
                <Player
                      ref={player => {
                        this.player = player;
                      }}
                      preload={"auto"}
                      autoPlay
                      playsInline>
                      <source
                          // src={"http://media.w3.org/2010/05/sintel/trailer.mp4"}
                          src={videoSrc} />
                      <ControlBar disableCompletely={true}  />
                </Player>
            </PlaylistItem>
        )
    }
}

export default class PlaylistItem extends React.Component {


    constructor(props) {
        super(props);

        this.handleActiveState = this.handleActiveState.bind(this);

    }

    componentDidMount() {
        this.handleActiveState( );
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.active !== prevProps.active) {

            this.handleActiveState( );

        }
    }

    handleActiveState() {

        if (this.props.active) {
            if (this.props.handleActive) {
                this.props.handleActive();
            }
        } else {
            if (this.props.handleInactive) {
                this.props.handleInactive();
            }
        }

    }


    render() {

        return (
                <div className="playlist__slide">
                    {this.props.children}
                </div>
        )
    }
}
