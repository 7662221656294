import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux'
import {createStore, applyMiddleware, compose} from 'redux'
import logger from 'redux-logger'
import thunk from 'redux-thunk'
import Reducers from './reducers'

import { getAllUrlParams } from './util/getAllUrlParams'

/** Components */
import App from './components/App'


class Exhibition extends React.Component {

    constructor(props) {
        super(props);

        var reducers = Reducers;
        var initializer = {};

        // Initialize the store
        const element = document.getElementById('root');
        initializer.app = Object.assign({}, element.dataset, getAllUrlParams(), {userTZ: Intl.DateTimeFormat().resolvedOptions().timeZone})

        this.store = createStore(reducers, initializer, compose(applyMiddleware(thunk, /*logger*/), window.devToolsExtension
            ? window.devToolsExtension() : f => f));
    }

    render() {
        return (
            <Provider store={this.store}>
                <App />
            </Provider>
        );
    }
}

ReactDOM.render(<Exhibition />, document.getElementById('root'));
