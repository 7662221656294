import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { diff, detailedDiff } from 'deep-object-diff';

/** Com */
import MealTime from './MealTime'
import FoodLegend from './FoodLegend'

/** Actions */
import { logCase } from '../actions/logs'

/**/
import { isEmpty } from '../util/object';

class MenuContainer extends Component {

    constructor(props) {
        super(props);

        this.LogMenuState = this.LogMenuState.bind(this);
    }

    componentDidMount() {
        this.LogMenuState("Menu got mounted");
    }

    componentDidUpdate(prevProps) {
        if (this.props.currentMenu !== prevProps.currentMenu) {
            this.LogMenuState("Menu updated");
        }
        if ( !isEmpty( diff( prevProps.menu, this.props.menu) ) ) { // check if there is any difference between objects

            console.log(detailedDiff( prevProps.menu, this.props.menu))

            this.props.logCase("notice", "Menu data updated", { days: detailedDiff(this.props.menu, prevProps.menu)} );
        }
    }

    componentWillUnmount() {
        this.LogMenuState("Menu getting unmounted");
    }


    LogMenuState(message, data) {
        let currentMenu = this.props.currentMenu;
        let currentMenuDetails = this.props.menu.filter( (dayPart) => dayPart.id == currentMenu );
        let cleanData = { ...currentMenuDetails['0'], stations: null}

        this.props.logCase("info", message, cleanData);
    }


    render() {

        let { storeDetails, menu, currentMenu } = this.props;
        if ( menu.length == 0 ) {
            return null;
        }

        let defaultMenu = 0;
        let MenuTabs = [];
        let MenuPanels = [];

        menu.forEach( (part, i) => {
            defaultMenu = part.id == currentMenu ? i : defaultMenu;
            MenuTabs.push( <Tab key={"tab-"+part.id}> {part.label} </Tab> )
            MenuPanels.push( <TabPanel key={"panel-"+part.id}> <MealTime details={part} /></TabPanel> )
        });


        return (
            <div className="menu">
                <div className="menu__header">
                    <h1> { storeDetails.name} </h1>
                </div>

                <Tabs selectedIndex={defaultMenu} onSelect={index => console.log(index)}>
                    <TabList>
                      { MenuTabs }
                    </TabList>

                    {MenuPanels}
                </Tabs>

                <FoodLegend />

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        storeDetails: state.store,
        menu: state.days,
        currentMenu: state.app.menuData.menu,
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        logCase: (type, message, cleanData) => {
            dispatch(logCase(type, message, cleanData));
        },
        // visibilityUpdate: (visibilityState) => {
        //     dispatch(pageVisible(visibilityState))
        // }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuContainer)
