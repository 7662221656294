import React, { Component } from 'react';
import { connect } from 'react-redux';

/** Components */
import MenuItem from './MenuItem'

class Station extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showAll: true,
        };

        this.toggleTiers = this.toggleTiers.bind(this);
        this.renderStation = this.renderStation.bind(this);
    }

    toggleTiers() {
        this.setState({showAll: !this.state.showAll});
    }

    /** Example of a recursive render in REACT */
    renderStation( station, showAll ) {
        let self = this;

        let sub_stations = station.sub_station ? station.sub_station.map( (sub_station) => self.renderStation(sub_station, showAll)) : null;
        let station_classes = [
            "col",
            "station",
            station.has_sub ? "station--parent" : null,
            station.is_sub ? "station--substation" : "station--not-substation",
        ]


        return (

            <div key={station.id} className={ station_classes.join(" ") }>
                <div className="station__header">
                    <strong>{station.label} </strong>
                    <button onClick={self.toggleTiers}> {showAll ? "Show Featured" : "Show All" } </button>
                </div>
                <div className="station__items">
                    { station.items.map( (item) => (
                            <MenuItem key={station.id+"-"+item.id} item={item} showAll={ showAll } />
                      ) ) }
                </div>
                { sub_stations ? (
                    <div className="station__sub-stations row">
                        { sub_stations }
                    </div>
                ) : null}
            </div>

        )
    }

    render() {
        let { station } = this.props;
        let { showAll } = this.state;

        return this.renderStation(station, showAll);
    }
}

const mapStateToProps = (state) => {
    return {
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        // InitializeApp: () => {
        //     dispatch(InitializeApp());
        // },
        // visibilityUpdate: (visibilityState) => {
        //     dispatch(pageVisible(visibilityState))
        // }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Station)
