const store = (state = {}, action) => {
    switch (action.type) {
        case 'SET_STORE':
            return { ...action.payload }
        case 'UPDATE_STORE':
            return { ...state, ...action.payload }
        default:
            return state
    }
}

export default store
