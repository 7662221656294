import { toScadStringfromMilitary } from '../util/time'

export const setDays = function( data, storeID ) {

    let processed = data['0'].map( (dayTime) => {
        dayTime.time = toScadStringfromMilitary( dayTime.starttime, dayTime.endtime )
        return dayTime
    } );

    return {
        type: 'SET_DAYS',
        payload: processed
    }

}
