const diningIcons = (state = {}, action) => {
    switch (action.type) {
        case 'SET_DINING_ICONS':
            return { ...action.payload }
        default:
            return state
    }
}

export default diningIcons
