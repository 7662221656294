import React, { Component } from 'react';
import { connect } from 'react-redux';

/** Components */
import FoodLegendIcon from './FoodLegendIcon';

class FoodLegend extends Component {

    constructor(props) {
        super(props);

        this.state = {
            diningIcons: [1, 4, 9, 7, 6, 3],
        }

    }

    render() {
        let { icons } = this.props;

        return (
            <div className="food-legend">
                { this.state.diningIcons.map( (id) => (
                    <div key={ "food-legend" + id } className="food-legend__item">
                        <FoodLegendIcon id={ id } className="food-legend__img" />
                        <div className="food-legend__label">
                            { icons[id].label }
                        </div>
                    </div>
                ) )}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        icons: state.diningIcons,
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        // InitializeApp: () => {
        //     dispatch(InitializeApp());
        // },
        // visibilityUpdate: (visibilityState) => {
        //     dispatch(pageVisible(visibilityState))
        // }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(FoodLegend)
