
export const capitalCase = function ( str, exceptions = []) {

  let defaultExceptions = [
      "and", "but", "for", 'nor', "in", "or", "with", //
      "a", "an", "the", "of",
  ];

  let updatedStr = str.split(" ").map( ( word, index ) => {
      return index == 0 || !defaultExceptions.includes(word) ? word.replace(/^\w/, c => c.toUpperCase()) : word.toLowerCase();
  }).join(" ");

  return updatedStr;

}
