const log = ( state = [], action) => {
    switch (action.type) {
        case 'SET_APP_LOG':
            state.push(action.payload)
            return state;
        default:
            return state
    }
}

export default log
