
export const toScadStringfromMilitary  = function ( startTime, endTime = null ) {
    let response = "";
    let timeObj = {
        startTime: toTimeArrayFromMilitary(startTime),
        endTime: endTime ? toTimeArrayFromMilitary(endTime) : undefined,
    };

    return scadTimeFromObject(timeObj);
}


export const toTimeArrayFromMilitary = function ( milTime ) {
    let timeArray = milTime.split(':');
    let time = {
        hour: Number(timeArray[0]),
        minute: Number(timeArray[1]),
        meridiem: 'a.m.',
    }

    // Object.values(time).includes(NaN);

    if ( time.hour >= 12 && time.hour <= 24 ) {
        if (time.hour > 12 ) {
            time.hour = time.hour - 12;
        }
        time.meridiem = 'p.m.';
    }


    return time;
}

export const scadTimeFromObject = function ( timeObjs ) {
    let validTime = validTimeObject( timeObjs );

    let { startTime, endTime } = timeObjs

    if ( validTime ) {
        if ( endTime ) {
            if (startTime.meridiem == endTime.meridiem) {
                return scadStyleTimeFormat(startTime, false) + "-" + scadStyleTimeFormat(endTime, true)
            } else {
                return  scadStyleTimeFormat(startTime, true) + " - " + scadStyleTimeFormat(endTime, true)
            }
        } else {
            return scadStyleTimeFormat(startTime, true)
        }
    }

    return validTime;
}


export const scadStyleTimeFormat = function ( timeObj, showMeridian = ture ) {

    if ( timeObj.hour == 12 && timeObj.minute == 0 ) {
        return "Noon"
    } else if ( (timeObj.hour == 0 || timeObj.hour == 24) && timeObj.minute == 0 ) {
        return "Midnight"
    }

    let response = timeObj.hour;
    response += timeObj.minute != 0 ? ":"+timeObj.minute : ""
    response += showMeridian ? " "+timeObj.meridiem : ""

    return response;
}


export const validTimeObject = function ( timeObjs ) {
    let validTime = true;

    Object.keys(timeObjs).forEach( (timeObj) => {
        validTime = Object.values(timeObj).includes(NaN) ? false : validTime;
    })

    return validTime;
}
