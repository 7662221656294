import React, { Component } from 'react';
import { connect } from 'react-redux';
import Slider from "react-slick";
import Masonry from 'react-masonry-component';

/** Componets */
import Station from './Station'

/** Utils */
import { chunk } from '../util/array';


class MealTime extends Component {

    constructor(props) {
        super(props);

        this.state = {
            settings: {
                    autoplay: true,
                    autoplaySpeed: 10000,
                    pauseOnHover: false,
                    fade: true,
                    dots: true,
                    infinite: true,
                    speed: 850,
                    slidesToShow: 1,
                    slidesToScroll: 1
                },
        }

        this.customPaging = this.customPaging.bind(this);
        this.prepareSlides = this.prepareSlides.bind(this);
        this.prepareStations = this.prepareStations.bind(this);
    }


    customPaging (i) {

        return (<button className="slick-dot">
            <div className="slick-dot-bg"> </div>
            <div className="slick-dot-value" > {++i} </div>
          </button>);
    }

    prepareSlides() {
        let response = [];
        let chunkVal = 6;

        let specialTest = ( item) => item.special && true ;
        let importantTest = ( item) => item.special || item.tier == "1" ;

        let stations = {
            special: this.prepareStations( specialTest ),
            // daily: this.prepareStations( (item) => !!!item.special && item.tier == "1"),
            important: this.prepareStations( importantTest ),
        };


        return stations;
    }

    prepareStations( testFunction ) {
        let {details, items} = this.props;

        // Get stations
        let stations_list = details.stations;

        // If needed filter stations
        let stationsToShow = this.props.stationsToShow;
        if(stationsToShow.length >= 1){
            stations_list = stations_list.filter( (station) => stationsToShow.includes(station.id) || stationsToShow.includes( parseInt(station.id) ) );
        }

        // Map stations to pontential substations
        let stations = stations_list.map( station => {
            let newStation = {...station};


            // This line takes a provided callback function that should return true or false, and filters items based on that.
            let stationItems = station.items
                                        .filter( item => {
                                            try {
                                                let itemState = (typeof testFunction === "function") && (typeof items[item] === 'object' && items[item] !== null) ? testFunction(items[item]) : false;
                                                return itemState;
                                            } catch {
                                                return false;
                                            }
                                        }).map( item => items[item] );


            // Handle items and items in substations if needed.
            let newItems = []
            let newSubStations = {}

            stationItems.forEach( function( item, index ) {

                if ( typeof item === 'object' && item !== null ) {
                    if ( !!item.sub_station_id ) {
                        if ( !!!newSubStations[item.sub_station_id] ) {
                            newSubStations[item.sub_station_id] = {
                                id: item.sub_station_id,
                                items: [],
                                label: item.sub_station,
                                note: "",
                                order_id: item.sub_station_order+"-"+item.sub_station_id,
                                price: "",
                                soup: 0,
                                is_sub: true,
                            }
                        }
                        newSubStations[item.sub_station_id].items.push(item);
                    } else {
                        newItems.push(item);
                    }
                } else {
                    console.error( index );
                    console.error( stationItems );
                    // setTimeout( ()=> {
                    //     location.reload(true);
                    // }, 5000);
                }

            });

            // Set station details
            newStation.items = newItems;
            newStation.sub_station = Object.keys(newSubStations).map( (station)=> newSubStations[station]).sort( (a,b)=>{
                  if (a.order_id < b.order_id) {
                    return -1;
                  }
                  if (a.order_id > b.order_id) {
                    return 1;
                  }
                  // names must be equal
                  return 0;
            });
            newStation.has_sub = newStation.sub_station.length > 0 ? true : false;

            return newStation;
        }).filter( (station, index) => station.items.length > 0 || station.sub_station.length > 0).slice(0, 8);



        return chunk(stations, this.props.stationsPerSlide);
    }

    render() {
        let self = this;
        let { details } = self.props;
        let { settings } = self.state;

        let specialStations = self.prepareSlides();

        return (
            <div className="time-part">
                <div className="time-part__stations">
                    <div className="time-part__label">
                        <h1>{ details.label } Menu</h1>
                    </div>
                    <div className="time-part__time">
                        <h2>{ details.time }</h2>
                    </div>
                    <Slider className="special-stations" {...settings} customPaging={this.customPaging}>
                        { specialStations.special.map( (slide, index) => (
                            <div key={"slide"+index} className="time-part__slide container-fluid">
                                <div className="time-part__station row">
                                    { slide.map((station) => ( <Station key={station.id} station={station} /> ) ) }
                                    <Masonry key={"station" + index }>
                                    </Masonry>
                                </div>
                            </div>
                            )) }
                    </Slider>

                    <Slider className="tier-1-stations" {...settings} customPaging={this.customPaging}>
                        { specialStations.important.map( (slide, index) => (
                            <div key={"slide"+index} className="time-part__slide container-fluid">
                                <div className="time-part__station row">
                                    { slide.map((station) => ( <Station key={station.id} station={station} /> ) ) }
                                    <Masonry key={"station" + index }>
                                    </Masonry>
                                </div>
                            </div>
                            )) }
                    </Slider>

                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        items: state.items,
        stationsToShow: state.monitor.field_stations ? state.monitor.field_stations : [],
        stationsPerSlide: state.monitor.field_stations_per_slide ? state.monitor.field_stations_per_slide : 4,
        // menuOn: true,
        // windowVisible: state.app.windowVisible,
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        // InitializeApp: () => {
        //     dispatch(InitializeApp());
        // },
        // visibilityUpdate: (visibilityState) => {
        //     dispatch(pageVisible(visibilityState))
        // }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(MealTime)
