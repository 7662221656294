import React, { Component } from 'react';
import { connect } from 'react-redux';

/** Components */
import Playlist from './PlaylistContainer'
import Menu from './MenuContainer'

/** Actions */
import { InitializeApp, prepApp, setAppMenu, fetchMonitor } from '../actions/app'

/** Utils */
import { validateRequest } from '../util/fetch-functions'


class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            monitor: "",
            version: "",
        }


        this.setVersion = this.setVersion.bind(this);
        this.fetchVersion = this.fetchVersion.bind(this);
        this.compareVersion = this.compareVersion.bind(this);
    }

    componentDidMount(){
        let self = this;

        self.props.InitializeApp();

        self.fetchVersion(self.setVersion);

        setInterval( function(){
            self.fetchVersion(self.compareVersion);

            self.props.setAppMenu();
        }, 60000);

    }


    fetchVersion( callback ) {
        let sign = this.props.sign;

        fetch('/json/js-v/'+sign)
            .then( validateRequest )
            .then( response => response.json())
            .then( timestamp => {
                callback( timestamp );
            }).catch(function(error) {
                console.log(error);
            });
    }

    setVersion( timestamp ) {
        this.setState( { version: timestamp.app,
                         monitor: timestamp.monitor
                     } );
    }

    compareVersion( newVersion ) {
        let version = this.state.version;
        let monitor = this.state.monitor;

        if ( version !== "" && version != newVersion.app ) {
            setTimeout( ()=> {
                location.reload(true);
            }, 3000);
        } else if ( monitor !== "" && monitor != newVersion.monitor ) {
            this.props.RefreshData();
            this.setState( { monitor: newVersion.monitor } );
        } else {
            // console.log("Browser not refreshed")
        }
    }

    render() {
        let { storeID, sign, orientation, menuOn, device, mealPeriod } = this.props

        let classes = [
            'app-content',
            'app-content--'+storeID,
            'app-content--'+device,
            'app-content--'+mealPeriod,
            'app-content--'+orientation,
            menuOn ? 'app-content--on' : 'app-content--off',
        ];


        return (
            <div className={ classes.join(' ') }>
                {this.props.sign === "" ?
                    <p className="app-content__label" style={{color:'red',fontStyle:'italic'}}>no display name provided</p>
                    :
                    <p className="app-content__label">Device: <strong>{device}</strong><br/>Hello, I am <strong>{sign}</strong> in the <strong>{orientation}</strong> orientation.</p>
                }
                { menuOn ? <Menu /> : <Playlist/> }
            </div>
        )
    }
}


const mapStateToProps = ( state ) => {
    return {
        menuOn: state.app.menuData && !!state.app.menuData.menu ? true : false,
        sign: state.app.sign,
        storeID: state.app.storeID,
        orientation: state.app.orientation,
        device: state.app.device,
        mealPeriod: state.app.mealPeriod,
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        InitializeApp: () => {
            dispatch(InitializeApp());
        },
        RefreshBrowser: () => {
            dispatch(InitializeApp());
        },
        RefreshData: () => {
            dispatch(fetchMonitor());
        },
        setAppMenu: () => {
            dispatch( prepApp() );
            dispatch( setAppMenu() );
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
