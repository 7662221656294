import React, { Component } from 'react';
import { connect } from 'react-redux';
import Slider from "react-slick";

/* Components */
import Slide from "./PlaylistSlide"


class PlaylistSlider extends Component {


    constructor(props) {
        super(props);

        // activeSlide needed to rerender slides
        this.state = {
            activeSlide: 0,
            settings: {
                // autoplay: true,
                // autoplaySpeed: 5000,
                fade: true,
                dots: true,
                infinite: true,
                className: 'slick-playlist',
                speed: 1000,
                slidesToShow: 1,
                slidesToScroll: 1,
                // timeout needed in beforeChange because slider breaks on fade when setting active slide
                beforeChange: (current, next) => setTimeout(() => this.setState({ activeSlide: next }), 1),
            },
        }

        this.slider = React.createRef();
    }


    render() {
        let self = this;

        return (
            <div className="playlist__slider">
                <Slider ref={self.slider} {...self.state.settings}>
                    { self.props.slides.map( (slide, index) => (
                        <Slide  key={slide.id}
                                className="playlist__slide"
                                slider={self.slider}
                                slide={slide}
                                orientation={self.props.orientation}
                                resourceHost={self.props.resourceHost}
                                active={ index == self.state.activeSlide}/>
                        )) }
                </Slider>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        orientation: state.app.orientation,
        resourceHost: state.app.resourceHost,
        // playlists: state.playlists,
        // windowVisible: state.app.windowVisible,
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        // InitializeApp: () => {
        //     dispatch(InitializeApp());
        // },
        // visibilityUpdate: (visibilityState) => {
        //     dispatch(pageVisible(visibilityState))
        // }
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(PlaylistSlider)



