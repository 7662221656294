import React, { Component } from 'react';
import { connect } from 'react-redux';



class FoodLegendIcon extends Component {

    render() {
        let { icon, className } = this.props;

        return (
            <div className={["food-legend-item", className].join(" ")}>
                <img src={icon.image} alt={icon.label+" Icon"} />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        icon: state.diningIcons[ownProps.id],
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        // InitializeApp: () => {
        //     dispatch(InitializeApp());
        // },
        // visibilityUpdate: (visibilityState) => {
        //     dispatch(pageVisible(visibilityState))
        // }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(FoodLegendIcon)
