import React, { Component } from 'react';
import { connect } from 'react-redux';

/** Components */
import FoodLegendIcon from './FoodLegendIcon';

/** Utils  */
import { capitalCase } from '../util/strings'

class MenuItem extends Component {

    render() {
        let { item, showAll } = this.props;
        let options = item.options.values.length > 0 && false ? item.options.values : [];


        let menuClasses = [
            "station__item",
            "menu-item",
            "menu-item--"+item.id,
            item.description ? "menu-item--has-description" : null,
            options.length > 0 ? "menu-item--has-options" : null,
        ]

        // if (!showAll && (!!!item.special) ) {
        //     return null
        // }

        if ( showAll && (!!item.special) ) {
            menuClasses.push("menu-item--special");
        } else if ( showAll && (item.tier == "1") ) {
            menuClasses.push("menu-item--tier-1");
        } else if ( showAll && (item.tier == "2") ) {
            menuClasses.push("menu-item--tier-2");
        } else if ( showAll ) {
            menuClasses.push("menu-item--tier-3");
        }

        let iconsArray = Object.keys( item.cor_icon );


        return (
            <div className={menuClasses.join(' ')}>
                <div className="menu-item__header">
                    <div className="menu-item__label"> { capitalCase(item.label) } </div>
                    <div className="menu-item__icons">
                        { iconsArray.map( ( id ) => (<FoodLegendIcon key={item.id+"-"+id} id={ id } className="menu-item__icon" />) ) }
                    </div>
                </div>
                <div className="menu-item__body">
                    { options.length > 0 ? item.options.values.map( ( option, index ) => (
                            <div key={ item.id + "-option-" + index } className={["menu-item__option", option.description ? "menu-item__option--has-desc" : "menu-item__option--no-desc", ].join(" ")}>
                                <div className="menu-item__option_header"> { capitalCase(option.label) } </div>
                                <div className="menu-item__option_description"> { option.description } </div>
                            </div>
                        )
                    ) : null }

                    { item.description ? (
                            <div className="menu-item__description">
                                <div dangerouslySetInnerHTML={ {__html: item.description} } />
                            </div>
                    ) : null }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        // windowVisible: state.app.windowVisible,
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        // InitializeApp: () => {
        //     dispatch(InitializeApp());
        // },
        // visibilityUpdate: (visibilityState) => {
        //     dispatch(pageVisible(visibilityState))
        // }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(MenuItem)
