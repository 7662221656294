const noCacheHeaders = new Headers({
    'pragma': 'no-cache',
    'cache-control': 'no-cache'
});

export const getFetchMethod = {
    'method': 'GET',
    'headers': noCacheHeaders,
};

export const postFetchMethod = {
    'method': 'POST',
    'headers': noCacheHeaders,
};


export const validateRequest = function (response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}
