import moment from 'moment'
import 'moment-timezone';

import { setDays } from './days'
import { setItems } from './items'
import { setPlaylist } from './playlist'
import { setDiningIcons } from './diningIcons'
import { fetching } from './fetching'

import { getAllUrlParams } from '../util/getAllUrlParams'
import { getFetchMethod } from '../util/fetch-functions'


export const InitializeApp = function() {


    return (dispatch, getState) => {

        dispatch( fetchMonitor ( ) );

        // setInterval( function() {
        //     dispatch( fetchMonitor ( ) );
        // }, 300000);
        // }, 30000);

    }
}

export const fetchMonitor = function() {

    return (dispatch, getState) => {

        let state = getState();
        let signId = state.app.sign;
        let jsonPath = state.app.test ? "/json/test/" : "/json/";


        fetch( jsonPath + 'display/' + signId + '.json', getFetchMethod)
            .then(response => response.json())
            .then(display => {

                dispatch(setMonitor(display));

                dispatch( fetchDiningLocation( ) );
                dispatch( fetchPlaylists( ) );

            }).catch(function(error) {
                console.log(error);
            });


    }
}



export const fetchDiningLocation = function() {

    return (dispatch, getState) => {
        dispatch( fetching(true));

        let state = getState();
        let storeID = state.monitor.field_ds_bon_appetit_cafe.data.id;
        let jsonPath = state.app.test ? "/json/test/" : "/json/";

        fetch(jsonPath + 'cafes/' + storeID + '.json', getFetchMethod)
            .then(response => response.json())
            .then(cafe => {
                // dispatch( setAppVersion( ) );
                dispatch( setStore( cafe.store ) );
                dispatch( setApp({storeID: cafe.id}) );
                dispatch( prepApp() );
                dispatch( setItems(cafe.items) );
                dispatch( setDays(cafe.menu.dayparts) );
                dispatch( setDiningIcons(cafe.store.cor_icons) );
                dispatch( setAppMenu() );
                dispatch( fetching(false) );
            }).catch(function(error) {
                console.log(error);
            });
    }
}

export const fetchPlaylists = function() {

    return (dispatch, getState) => {
        dispatch( fetching(true));

        let state = getState();
        let playlists = state.monitor.field_ds_playlist.data;

        let filterPlaylists = playlists
                                .filter( (playlist) => playlist.relationships.field_playlist.data && true )
                                .map( (playlist) => ( { ...playlist.attributes,
                                                        ...playlist.relationships,
                                                        id: playlist.id,
                                                        type: playlist.type
                                                    } )
                                    )


        filterPlaylists.forEach( ( playlist, i ) => {

            let playlistID = playlist.field_playlist.data.id
            let jsonPath = state.app.test ? "/json/test/" : "/json/";

            fetch(jsonPath + 'playlist/' + playlistID + '.json', getFetchMethod)
                .then( response => response.json())
                .then( field_playlist => {

                    playlist.field_playlist = field_playlist;

                    dispatch( setPlaylist(playlist) );
                }).catch(function(error) {
                    console.log(error);
                });
        });

    }
}


export const setAppVersion = function() {

    return (dispatch, getState) => {
        let state = getState();
        let sign = state.app.sign;


        fetch('/json/js-v/'+sign, getFetchMethod)
            .then( response => response.json())
            .then( timestamp => {
                dispatch( setApp({version: timestamp.endpoint}) );
            }).catch(function(error) {
                console.log(error);
            });

    }
}

export const prepApp = function( ) {

    return (dispatch, getState) => {
        let state = getState();

        dispatch( updateTime() );
    }
}


export const setAppMenu = function( ) {

    return (dispatch, getState) => {
        let state = getState();

        let storeTZ = state.store.timezone;
        let userTZ = state.app.userTZ;
        let time = state.app.time;
        let today = state.app.chosenDate;
        let now = moment.tz(today+" "+ time, storeTZ);

        let menuData = {
            menu: "",
            nextMenu: "",
        };

        let menus = state.days;

        menus.forEach( function(menu,i) {
            if (!!!menuData.menu && !!!menuData.nextMenu ) { // once set don't modify.
                let nextMenu = menus[(i+1)];

                let currentStartTime = moment.tz(today+" "+ menu.starttime, storeTZ);
                let currentEndTime = moment.tz(today+" "+ menu.endtime, storeTZ);
                let nextStartTime = nextMenu ? moment.tz(today+" "+ nextMenu.starttime, storeTZ) : null;

                // Modification to times
                switch(menu.id) {
                  case "1":
                    // code block
                    currentStartTime = currentStartTime.subtract( 10, 'minutes');
                    break;
                }

                // Comparisons
                if (now.isBetween( currentStartTime, currentEndTime, null, '[]')) {
                    menuData = { menu: menu.id, nextMenu: "" }
                } else if (nextMenu &&  now.isBetween( currentEndTime, nextStartTime, null, '[)' )) {
                    menuData = { menu: "", nextMenu: nextMenu.id }
                }
            }
        });

        let mealPeriod;
        let lunchStart = moment.tz( today+" "+ time, storeTZ).set({'hour': 10, 'minute': 30});
        let lunchEnd = moment.tz( today+" "+ time, storeTZ).set({'hour': 16, 'minute': 30});

        if ( now.isBefore(lunchStart) ) {
            mealPeriod = 'breakfast';
        } else if ( now.isAfter(lunchEnd) ) {
            mealPeriod = 'dinner';
        } else {
            mealPeriod = 'lunch';
        }


        let appData = {
            menuData: menuData,
            mealPeriod: mealPeriod
        }

        dispatch( setApp(appData) );
    }
}

export const updateTime = function( data ) {

    return (dispatch, getState) => {
        let state = getState();

        let tz = state.app.userTZ;
        let now = moment.tz(tz).tz("America/New_York");

        let today = now.format('YYYY-MM-DD')
        let time = now.add( 5, 'minutes').format('HH:mm')

        let appData = { today: today,
                        chosenDate: today,
                        time: time,
                        keepTime: 1, }

        dispatch( setApp(appData) );
    }
}

export const setApp = function( data ) {
    let urlParams = getAllUrlParams();
    let preparedData = Object.assign(data, urlParams );


    return {
        type: 'SET_APP_DATA',
        payload: preparedData
    }
}

export const setStore = function( data ) {

    return {
        type: 'UPDATE_STORE',
        payload: data
    }
}

export const setMonitor = function( data ) {

    let monitor = {
        ...data.attributes,
        ...data.relationships,
        id: data.id,
    }

    return {
        type: 'SET_MONITOR',
        payload: monitor
    }
}
