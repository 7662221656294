import { combineReducers } from 'redux'

import app from './app'
import days from './days'
import items from './items'
import diningIcons from './diningIcons'
import fetching from './fetching'
import monitor from './monitor'
import store from './store'
import playlist from './playlist'
import log from './log'

const buildingsApp = combineReducers({
    app: app,
    store: store,
    days: days,
    items: items,
    monitor: monitor,
    playlists: playlist,
    diningIcons: diningIcons,
    fetching: fetching,
    log: log,
})

export default buildingsApp
